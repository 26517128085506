import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";

const AreaChart = (props) => {
  const { id } = props
  // let { id } = useParams();
  //const id= '62c55ef09239c5257e9a3dfc'
  let minmax_alldata = []
  const [state, setState] = useState([])

  useEffect(() => {
    const data = fetch(`https://project-demos.com/pcaa/wp-json/aqi-min-max-data/v2/api/${id}`)
      .then((response) => {
        response.json().then((data) => {
          data.forEach((item, index) => {
            if (index > 80) {
              return false
            }
            else {
              minmax_alldata.push([item.maxaqi_time ? item.maxaqi_time.split("T")[0] : 0, item.aqi_min, item.aqi_max, item.maxaqi_time])
              //setState([...state,[item.time_stamp.split(" ")[0],item.aqi_min,item.aqi_max,item.maxaqi_time]])
              return true
            }
          })
          // if(state.length==0){
          setState(minmax_alldata)
          //}

        }).catch((err) => {
          console.log(err);
        })
      });
  }, [id])

  const getmindata = () => {
    return state.map((item, index) => {
      return [item[0], item[1]]
    })
  }
  const getmaxdata = () => {
    return state.map((item, index) => {
      return [item[0], item[2]]
    })
  }



  const series = {
    series: [
      {
        name: "Min AQI",
        data: getmindata()
      },
      {
        name: "Max AQI",
        data: getmaxdata()
      }
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        stacked: true,
        events: {
          selection: function (chart, e) {
            console.log(new Date(e.xaxis.min));
          }
        }
      },
      colors: ["#ABD162", "#A06A7B"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title:{
          text: 'AQI',
          // offsetX: 100,
          style: {
            cssClass: 'apexcharts-xaxis-title',
          }
        }
      }
    }
  };

  return (
    <>
      <ReactApexChart
        options={series.options}
        series={series.series}
        type="area"
        height={350}
      />
      <p className="text-center">{'Date'}</p>
    </>
  );
};

export default AreaChart;
