import axios from "axios";
import { put } from "redux-saga/effects";
import { baseUrl } from "..";
import { setRecords, setSingleRecord } from "../reducers/getapiDataSlice";

export function* getapidataAsync(action) {

  const { url, lat, lang, singlRecord, sponseredbybok, fromwp, setType = null } = action.payload;
  let fullUrl = ''
  if (fromwp) {
    fullUrl = url;
  } else {
    fullUrl = '/data.json';
     fullUrl = baseUrl + url;
  }
  if (url) {
    try {
      const data = yield axios.get(fullUrl).then(({ data }) => data);
      if (setType) {
        yield put({ type: setType, payload: { data } });
      } else {
        if (fromwp) {
          yield put({ type: setRecords.type, payload: { data: data, fromwp: fromwp } });
        } else {
          if (singlRecord) {
            yield put({ type: setSingleRecord.type, payload: data, id: url, lat: lat, lang: lang, sponseredbybok: sponseredbybok });
          } else {
            yield put({ type: setRecords.type, payload: data, id: url, lat: lat, lang: lang, sponseredbybok: sponseredbybok });
          }
        }
      }
    } catch (e) {
      yield put({
        type: "getapiData/setErrors",
        payload: { errorIn: action.type, message: e.message },
      });
    }
  } else {
    yield put({
      type: "getapiData/setErrors",
      payload: { errorIn: action.type, message: "No url specified!" },
    });
  }
}